import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import classNames from 'classnames';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  canBeEditable,
  readOnlyPercentRender,
  readOnlyPriceRender,
} from '@core/utils/methods';
import { ACTUAL_DATE, DECIMAL_VALIDATOR } from '@core/utils/validators';
import { DOLLAR_PREFIX, PERCENT_PREFIX } from '@models/constants';
import {
  EditableComponentProps,
  LeasingPermission,
  OptionProps,
} from '@models/interfaces';
import { DateWorkerString } from '@models/types';
import { Panel, RADTable } from '@shared/components';
import {
  Autocomplete,
  Checkbox,
  Coordinate,
  DatePicker,
  Input,
  InputNumber,
  PrimaryButton,
  Select,
  TextArea,
} from '@shared/modules';
import { DDVSelectors, ProjectFuzeIDsSelectors } from '@store/selectors';

type LeaseAudiPanelProps = {
  projectId: string;
  form: FormInstance;
  permissions: LeasingPermission.Browse;
  leasingAgents: OptionProps[];
  onSubmit: () => Promise<void>;
  onCancel: VoidFunction;
  onValuesChange: VoidFunction;
  controlsAreDisabled: boolean;
  auditDate: DateWorkerString;
} & Pick<EditableComponentProps, 'isEditing' | 'isActive'>;

export const LeaseAuditPanel: FC<LeaseAudiPanelProps> = ({
  auditDate,
  isEditing,
  isActive,
  projectId,
  form,
  leasingAgents,
  onSubmit,
  onCancel,
  onValuesChange,
  controlsAreDisabled,
  permissions: {
    leaseAuditInformationFields: {
      leaseAuditComplete,
      leaseAuditBy,
      documentAudited,
      documentDate,
      agreementTypeId,
      currentRentAmount,
      escalator,
      termLengthOrExtension,
      currentTermStartDate,
      currentTermEndDate,
      finalTermEndDate,
      mostRecentSADate,
      mostRecentTowerSAPercentage,
      isMostRecentAdequate,
      mostRecentFoundationSAPercentage,
      mostRecentSATIARevCodeId,
      leasingNote,
      separateGeneratorSpace,
      generatorDetails,
      azimuthA,
      azimuthB,
      azimuthD,
      azimuthG,
    },
    leaseAuditTableFields,
  },
}: LeaseAudiPanelProps) => {
  const projectFuzeIDsTableVisible = useSelector(
    ProjectFuzeIDsSelectors.getProjectFuzeIDsTableVisible,
  );
  const {
    StructuralTIARevCodeDDV,
    TowerOwnerDDV,
    AgreementTypeDDV,
    TowerTypeDDV,
    EquipmentTypeDDV,
  } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes([
      'StructuralTIARevCode',
      'TowerOwner',
      'AgreementType',
      'TowerType',
      'EquipmentType',
    ]),
  );

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isActive, isEditing]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <div className="prov-lease-audit">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'fuze-ids-table-open': projectFuzeIDsTableVisible,
        })}
      >
        <Panel header="Lease Audit Information" className="lease-audit-panel">
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            onValuesChange={onValuesChange}
            form={form}
          >
            <Row>
              <Col span="12">
                <DatePicker
                  id="leaseAuditComplete"
                  label="Audit Date"
                  isEditing={canBeEditable(leaseAuditComplete, isEditing)}
                  formItemProps={{
                    rules: ACTUAL_DATE(auditDate),
                  }}
                />
                <Autocomplete
                  id="leaseAuditBy"
                  label="Audit By"
                  options={leasingAgents}
                  isEditing={canBeEditable(leaseAuditBy, isEditing)}
                />
                <Input
                  id="documentAudited"
                  label="Document Audited"
                  isEditing={canBeEditable(documentAudited, isEditing)}
                />
                <DatePicker
                  id="documentDate"
                  label="Document Date"
                  isEditing={canBeEditable(documentDate, isEditing)}
                />
                <Autocomplete
                  id="agreementTypeId"
                  label="Agreement Type"
                  options={AgreementTypeDDV}
                  isEditing={canBeEditable(agreementTypeId, isEditing)}
                />
                <Autocomplete
                  id="towerOwnerId"
                  label="Tower Owner"
                  options={TowerOwnerDDV}
                  isEditing={false}
                />
                <Input
                  id="towerOwnerSiteID"
                  label="Tower Owner Site ID"
                  isEditing={false}
                />
                <Input
                  id="streetAddress"
                  label="Street Address"
                  isEditing={false}
                />
                <Input id="city" label="City" isEditing={false} />
                <Input id="stateName" label="State" isEditing={false} />
                <Input id="zip" label="Zip" isEditing={false} />
                <Input id="countyName" label="County" isEditing={false} />
                <Input
                  id="jurisdictionName"
                  label="Jurisdiction"
                  isEditing={false}
                />
                <Coordinate id="latitude" label="Latitude" isEditing={false} />
                <Coordinate
                  id="longitude"
                  label="Longitude"
                  isEditing={false}
                />
                <InputNumber
                  id="currentRentAmount"
                  label="Current Rent Amount"
                  elementProps={{ formatter: DOLLAR_PREFIX }}
                  readOnlyElementRender={readOnlyPriceRender}
                  isEditing={canBeEditable(currentRentAmount, isEditing)}
                />
                <Input
                  id="escalator"
                  label="Escalator"
                  isEditing={canBeEditable(escalator, isEditing)}
                />
              </Col>
              <Col span="12">
                <Input
                  id="termLengthOrExtension"
                  label="Term Length/Extensions"
                  isEditing={canBeEditable(termLengthOrExtension, isEditing)}
                />
                <DatePicker
                  id="currentTermStartDate"
                  label="Current Term Start Date"
                  isEditing={canBeEditable(currentTermStartDate, isEditing)}
                />
                <DatePicker
                  id="currentTermEndDate"
                  label="Current Term End Date"
                  isEditing={canBeEditable(currentTermEndDate, isEditing)}
                />
                <DatePicker
                  id="finalTermEndDate"
                  label="Final Term End Date"
                  isEditing={canBeEditable(finalTermEndDate, isEditing)}
                />
                <DatePicker
                  id="mostRecentSADate"
                  label="Most Recent SA Date"
                  isEditing={canBeEditable(mostRecentSADate, isEditing)}
                />
                <div className="lease-audit-panel__row-wrapper">
                  <InputNumber
                    id="mostRecentTowerSAPercentage"
                    label="Most Recent Tower SA Percentage"
                    elementProps={{ formatter: PERCENT_PREFIX }}
                    formItemProps={{ rules: DECIMAL_VALIDATOR }}
                    className={classNames(
                      'most-recent-tower-sa-percentage',
                      'ant-col-17',
                    )}
                    readOnlyElementRender={readOnlyPercentRender}
                    isEditing={canBeEditable(
                      mostRecentTowerSAPercentage,
                      isEditing,
                    )}
                  />
                  <Checkbox
                    id="isMostRecentAdequate"
                    className={classNames('adequate', 'ant-col-5')}
                    label="Adequate"
                    formItemProps={{
                      wrapperCol: { span: 5 },
                      labelCol: { span: 16 },
                    }}
                    isEditing={canBeEditable(isMostRecentAdequate, isEditing)}
                  />
                </div>
                <InputNumber
                  id="mostRecentFoundationSAPercentage"
                  label="Most Recent Foundation SA Percentage"
                  elementProps={{ formatter: PERCENT_PREFIX }}
                  readOnlyElementRender={readOnlyPercentRender}
                  isEditing={canBeEditable(
                    mostRecentFoundationSAPercentage,
                    isEditing,
                  )}
                />
                <Autocomplete
                  id="mostRecentSATIARevCodeId"
                  label="SA TIA Rev Code"
                  options={StructuralTIARevCodeDDV}
                  isEditing={canBeEditable(mostRecentSATIARevCodeId, isEditing)}
                />
                <TextArea
                  id="leasingNote"
                  label="Leasing Notes"
                  isEditing={canBeEditable(leasingNote, isEditing)}
                />
                <Select
                  id="towerTypeId"
                  label="Tower Type"
                  options={TowerTypeDDV}
                  isEditing={false}
                />
                <Input
                  id="towerHeight"
                  label="Tower Height"
                  isEditing={false}
                />
                <Input
                  id="leasedGroundSpace"
                  label="Leased Ground Space"
                  isEditing={false}
                />
                <Select
                  id="equipmentTypeId"
                  label="Equipment Type"
                  options={EquipmentTypeDDV}
                  isEditing={false}
                />
                <Input
                  id="separateGeneratorSpace"
                  label="Separate Generator Space"
                  isEditing={canBeEditable(separateGeneratorSpace, isEditing)}
                />
                <Input
                  id="generatorDetails"
                  label="Generator Details"
                  isEditing={canBeEditable(generatorDetails, isEditing)}
                />
                <Input
                  id="sqInchesEquipmentAllowed"
                  label="Sq. Inches Equipment Allowed"
                  isEditing={false}
                />
                <Input
                  id="sqInchesEquipmentUsed"
                  label="Sq. Inches Equipment Used"
                  isEditing={false}
                />
                <Input
                  id="sqInchesCablesAllowed"
                  label="Sq. Inches Cables Allowed"
                  isEditing={false}
                />
                <Input
                  id="sqInchesCablesUsed"
                  label="Sq. Inches Cables Used"
                  isEditing={false}
                />
              </Col>
            </Row>
          </Form>
        </Panel>
        <Panel header="Azimuth Information" className="lease-audit-panel">
          <Form onValuesChange={onValuesChange} form={form}>
            <Row>
              <Col span="6">
                <InputNumber
                  id="azimuthA"
                  label="Azimuth A"
                  isEditing={canBeEditable(azimuthA, isEditing)}
                />
              </Col>
              <Col span="6">
                <InputNumber
                  id="azimuthB"
                  label="Azimuth B"
                  isEditing={canBeEditable(azimuthB, isEditing)}
                />
              </Col>
              <Col span="6">
                <InputNumber
                  id="azimuthG"
                  label="Azimuth G"
                  isEditing={canBeEditable(azimuthG, isEditing)}
                />
              </Col>
              <Col span="6">
                <InputNumber
                  id="azimuthD"
                  label="Azimuth D"
                  isEditing={canBeEditable(azimuthD, isEditing)}
                />
              </Col>
            </Row>
          </Form>
        </Panel>
        <RADTable
          isEditing={isEditing}
          permissions={leaseAuditTableFields}
          projectId={projectId}
        />
      </div>
      {isEditing && (
        <div className="prov-leasing-handle__btns">
          <div className="action-wrap">
            <PrimaryButton
              title="Submit"
              disabled={controlsAreDisabled}
              onClick={onSubmit}
            />
            <PrimaryButton
              title="Cancel"
              type="default"
              disabled={controlsAreDisabled}
              onClick={onCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
};
