import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Input as AntInput } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { FormItem, PrimaryButton } from '@shared/modules';
import { getReadonlyElementFunction } from '../../models/constants';
import { FormElement } from '../../models/interfaces';

import './styles.scss';

export const ShowMoreInput: FC<FormElement<TextAreaProps>> = ({
  id,
  form,
  data,
  elementProps: { onFocus, ...elementProps } = {},
  readOnlyElementRender,
  isEditing,
  ...props
}: PropsWithChildren<FormElement<TextAreaProps>>) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    form?.setFieldsValue({
      [id]: isExpanded || isEditing ? data : `${data?.slice(0, 100)}...`,
    });
  }, [form, data, isExpanded, isEditing]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const textArea = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    if (textArea.current) {
      textArea.current.style.height = 'auto';
      textArea.current.style.height = `${textArea.current.scrollHeight}px`;
    }
  };

  return (
    <div
      className={
        !isEditing && data?.length > 100
          ? 'prov-textarea-show-more__view'
          : 'prov-textarea-show-more'
      }
    >
      <FormItem
        id={id}
        editingElement={
          <AntInput.TextArea
            className="model-input"
            id={id}
            onInput={handleInput}
            autoSize={{ minRows: 1 }}
            {...elementProps}
          />
        }
        readOnlyElement={getReadonlyElementFunction<TextAreaProps>(
          id,
          props,
          readOnlyElementRender,
        )}
        isEditing={isEditing}
        {...props}
      />
      {!isEditing && data?.length > 100 && (
        <PrimaryButton
          title={isExpanded ? 'Show less' : 'Show more'}
          type="default"
          onClick={toggleExpanded}
        />
      )}
    </div>
  );
};
