import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MAIN_PACE_TASK_IDs,
  PaceTaskType,
} from '@symfa-inc/providence-verizon-types';
import { Form } from 'antd';
import { Store } from 'rc-field-form/es/interface';
import {
  HttpService,
  ObjectComparatorService,
  ObjectDifferencesService,
} from '@core/services';
import { LeasingHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  isValidForm,
  momentizeObjectDates,
  transformFields,
} from '@core/utils/methods';
import { EditableComponentProps, LeasingPermission } from '@models/interfaces';
import {
  LeaseAuditData,
  LeasingLeaseAuditUpdateData,
  UserPermissionKeys,
} from '@models/types';
import { NotificationsLoader } from '@shared/components/notifications';
import { CommonActions, ProjectActions } from '@store/actions';
import { ProjectsEffects } from '@store/effects';
import {
  LeasingSelectors,
  PaceTasksSelectors,
  RADSelectors,
} from '@store/selectors';
import { LEASE_AUDIT_DATES } from './models/constants';
import { LeaseAuditPanel } from './lease-audit-panel';

import './styles.scss';

type AreaLeaseAuditProps = {
  currentForm: Store;
  onSubmit: (projectId: string) => Promise<void>;
  onCancel: () => void;
} & Omit<
  Parameters<typeof LeaseAuditPanel>[0],
  'leasingAgents' | 'onSubmit' | 'onCancel' | 'projectId' | 'auditDate'
>;

const EngineeringLeaseAudit: FC<AreaLeaseAuditProps> = ({
  form,
  onSubmit,
  ...props
}: AreaLeaseAuditProps) => {
  const { leasingInformation, leasingSite, currentProject, leasingAgents } =
    useSelector(LeasingSelectors.getLeasingSummary);

  const { leaseAuditComplete } = useSelector(
    PaceTasksSelectors.getMainPaceTaskDates(
      PaceTaskType.ActualDate,
      'leaseAuditComplete',
    ),
  );

  useEffect(() => {
    const {
      state,
      county,
      jurisdiction,
      towerType,
      equipmentType,
      towerOwner,
      ...restLeasingSiteInfo
    } = leasingSite;

    if (leasingInformation?.leaseAudit) {
      const {
        leaseAudit: {
          documentDate,
          currentTermStartDate,
          currentTermEndDate,
          finalTermEndDate,
          mostRecentSADate,
          ...restData
        },
      } = leasingInformation;

      const { agreementType, mostRecentSATIARevCode, ...momentizedData } = {
        ...transformFields(restData, [
          'agreementType',
          'mostRecentSATIARevCode',
        ]),
        ...momentizeObjectDates(
          {
            leaseAuditComplete,
            documentDate,
            currentTermStartDate,
            currentTermEndDate,
            finalTermEndDate,
            mostRecentSADate,
          } as Partial<LeasingLeaseAuditUpdateData>,
          LEASE_AUDIT_DATES,
        ),
      };

      form.setFieldsValue({
        agreementTypeId: agreementType,
        mostRecentSATIARevCodeId: mostRecentSATIARevCode,
        ...momentizedData,
      } as Partial<LeaseAuditData>);
    }

    form.setFieldsValue({
      stateName: state?.name,
      countyName: county?.name,
      jurisdictionName: jurisdiction?.jurisdictionName,
      towerTypeId: towerType?.id,
      equipmentTypeId: equipmentType?.id,
      towerOwnerId: towerOwner,
      leasingNote: leasingInformation?.leasingNote,
      ...restLeasingSiteInfo,
    } as Partial<LeaseAuditData>);
  }, [leasingSite, currentProject, leasingInformation, leaseAuditComplete]);

  return (
    <LeaseAuditPanel
      projectId={currentProject.id}
      form={form}
      leasingAgents={leasingAgents}
      onSubmit={() => onSubmit(currentProject.id)}
      auditDate={leaseAuditComplete}
      {...props}
    />
  );
};

const LeasingLeaseAudit: FC<AreaLeaseAuditProps> = ({
  form,
  onSubmit,
  ...props
}: AreaLeaseAuditProps) => {
  const projectId = useSelector(LeasingSelectors.getProjectId);
  const { leasingAgents, ...formData } = useSelector(
    LeasingSelectors.getLeaseAudiData,
  );

  useEffect(() => {
    form.setFieldsValue(
      momentizeObjectDates(formData as LeaseAuditData, LEASE_AUDIT_DATES),
    );
  }, []);

  return (
    <LeaseAuditPanel
      projectId={projectId}
      form={form}
      leasingAgents={leasingAgents}
      onSubmit={() => onSubmit(projectId)}
      auditDate={formData.leaseAuditComplete}
      {...props}
    />
  );
};

interface LeaseAuditProps extends EditableComponentProps {
  area?: UserPermissionKeys;
  permissions?: LeasingPermission.Browse;
}

export const LeaseAudit: FC<LeaseAuditProps> = ({
  area,
  permissions,
  toggleEditing,
  ...props
}: PropsWithChildren<LeaseAuditProps>) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const leasingFetching = useSelector(LeasingSelectors.isFetching);
  const { RAD, originalRADInfo } = useSelector(RADSelectors.getRADInfo);

  const [initialForm, setInitialForm] = useState<Store>({});
  const [currentForm, setCurrentForm] = useState<Store>({});

  const arraysAreEqual = ObjectComparatorService.arraysCompare(
    [initialForm, originalRADInfo],
    [currentForm, RAD],
    true,
  );

  const onValuesChange = (): void => {
    if (Object.keys(form.getFieldsValue()).length) {
      setCurrentForm(form.getFieldsValue());

      if (!Object.keys(initialForm).length) {
        setInitialForm(form.getFieldsValue());
      }
    }
  };

  const controlsAreDisabled = leasingFetching || arraysAreEqual;

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!arraysAreEqual));
  }, [arraysAreEqual]);

  const onSubmit = async (projectId: string): Promise<void> => {
    const diff = ObjectDifferencesService.getObjectsDiff(
      initialForm,
      currentForm,
    );

    if (await isValidForm(form, Object.keys(diff))) {
      try {
        await HttpService.getHttpRequests(LeasingHttpService).updateLeaseAudit(
          projectId,
          area as 'engineering' | 'leasing',
          {
            ...momentizeObjectDates<Partial<LeasingLeaseAuditUpdateData>>(
              diff,
              LEASE_AUDIT_DATES,
              true,
            ),
            RAD,
          },
        );

        ProjectsEffects.updatePaceTaskFieldsByConfig(currentForm, [
          {
            id: MAIN_PACE_TASK_IDs.leaseAuditComplete,
            dateType: PaceTaskType.ActualDate,
            field: 'leaseAuditComplete',
          },
        ]);

        setInitialForm(currentForm);

        if (props?.isEditing) {
          toggleEditing?.();
        }

        NotificationsLoader.notificationSuccess(
          `Lease Audit has been updated!`,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onCancel = (): void => {
    form.setFieldsValue({
      ...momentizeObjectDates(
        initialForm as LeasingLeaseAuditUpdateData,
        LEASE_AUDIT_DATES,
      ),
    });

    dispatch(ProjectActions.updateProjectRADAction.done(originalRADInfo));
    setCurrentForm(initialForm);

    if (props?.isEditing) {
      toggleEditing?.();
    }
  };

  return area === 'engineering' ? (
    <EngineeringLeaseAudit
      form={form}
      onSubmit={onSubmit}
      onCancel={onCancel}
      currentForm={currentForm}
      controlsAreDisabled={controlsAreDisabled}
      permissions={permissions!}
      onValuesChange={onValuesChange}
      {...props}
    />
  ) : (
    <LeasingLeaseAudit
      form={form}
      onSubmit={onSubmit}
      onCancel={onCancel}
      currentForm={currentForm}
      controlsAreDisabled={controlsAreDisabled}
      permissions={permissions!}
      onValuesChange={onValuesChange}
      {...props}
    />
  );
};
